import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import Layout from '~/components/Common/Layout'
import Pager from '~/components/Common/Pager'
import { Query } from '~/types/graphql-types'
import { STYLE } from '~/utils/constant'
import { getGatsbyImageData, rem } from '~/utils/helper'

interface EventIndexProps {
  data: Query
  pageContext: {
    currentPage: number
    limit: number
    skip: number
    totalPage: number
  }
}

const eventIndexStyle = css`
  max-width: var(--width-max-content);
  margin-top: calc(var(--height-header) + 72px);
  margin-right: auto;
  margin-left: auto;
  padding-right: 34px;
  padding-left: 34px;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    margin-top: 80px;
  }

  @media screen and (${STYLE.MEDIA.PC}) {
    padding-right: 2px;
    padding-left: 2px;
  }

  & > .title {
    position: relative;
    padding-bottom: 32px;
    color: var(--color-text);
    font-size: ${rem(32)};
    font-weight: bold;
    line-height: 1;
    text-align: center;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 64px;
      height: 4px;
      transform: translateX(-50%);
      border-radius: 64px;
      background: var(--color-background-key);
    }
  }

  & > .contentwrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      margin-top: 80px;
      column-gap: 32px;
      row-gap: 32px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }
  }

  & > .contentwrapper > .content {
    width: 100%;

    &:not(:first-of-type) {
      margin-top: 24px;
    }

    @media screen and (${STYLE.MEDIA.TABLET}) {
      &:not(:first-of-type) {
        margin-top: 0;
      }
    }
  }

  & > .contentwrapper > .content > .button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 32px;
    transition: var(--hover-button-transition);
    border-radius: 16px;
    background: var(--color-background-bright);
    color: var(--color-text);
    text-decoration: none;

    &:hover {
      opacity: var(--hover-button-opacity);
    }
  }

  & > .contentwrapper > .content > .button > .contentheader > .status {
    display: inline-block;
    padding: 6px 16px;
    border-radius: 4px;
    background: var(--color-background-key);
    color: var(--color-background-bright);
    font-size: ${rem(12)};
    line-height: 1;

    &.-finish {
      background: var(--color-background-gray400);
    }
  }

  & > .contentwrapper > .content > .button > .contentbody {
    margin-top: 16px;
  }

  & > .contentwrapper > .content > .button > .contentbody > .imagewrapper {
    overflow: hidden;
    border: 1px solid var(--color-bluegray50);
    aspect-ratio: 244 / 120;
  }

  & > .contentwrapper > .content > .button > .contentbody > .imagewrapper > .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & > .contentwrapper > .content > .button > .contentbody > .date {
    display: block;
    margin-top: 24px;
    font-size: ${rem(12)};
    font-weight: bold;
    letter-spacing: 1.2px;
    line-height: 1;
  }

  & > .contentwrapper > .content > .button > .contentbody > .title {
    display: -webkit-box;
    margin-top: 16px;
    overflow: hidden;
    font-size: ${rem(16)};
    font-weight: bold;
    letter-spacing: -0.8px;
    line-height: 1.75;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      min-height: 84px;
    }
  }

  & > .pagerContent {
    margin-top: 40px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 64px;
    }
  }
`

const breadcrumb = [
  {
    label: 'セミナー・イベント',
  },
]

const seo = {
  title: 'セミナー・イベント',
}

export const query = graphql`
  query ($limit: Int!, $skip: Int!) {
    allFile(filter: { name: { eq: "cms-event" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          url
          name
          id
        }
      }
    }
    allMicrocmsStgEvent(limit: $limit, skip: $skip) {
      edges {
        node {
          event_date
          event_time_end
          event_time_start
          id
          image_catch {
            url
            height
            width
          }
          title
          status
          stgEventId
        }
      }
    }
  }
`

const EventIndexPage = ({ data, pageContext }: EventIndexProps) => {
  const pager = {
    path: '/event',
    currentPage: pageContext.currentPage,
    totalPage: pageContext.totalPage,
  }
  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      <main className={eventIndexStyle}>
        <h1 className="title">イベント</h1>
        <ul className="contentwrapper">
          {data &&
            data.allMicrocmsStgEvent.edges.map((edge) => {
              const item = edge.node
              if (!item) return
              const statusClassName =
                String(item.status) == '終了' || String(item.status) == '満席' ? 'status -finish' : 'status'
              const gatsbyImageData =
                item.image_catch && item.image_catch.url ? getGatsbyImageData(data.allFile, item.image_catch.url) : null
              return (
                <li className="content" key={item.id}>
                  <Link to={`/event/${item.stgEventId}`} className="button">
                    <div className="contentheader">
                      <span className={statusClassName}>{item.status}</span>
                    </div>
                    <div className="contentbody">
                      <div className="imagewrapper">
                        <GatsbyImage
                          className="logo"
                          image={gatsbyImageData}
                          alt={item.title || ''}
                          objectFit="contain"
                          objectPosition="0% 50%"
                        />
                      </div>
                      <time className="date">
                        {item.event_date}
                        {item.event_time_start}
                        {item.event_time_end}
                      </time>
                      <h2 className="title">{item.title}</h2>
                    </div>
                  </Link>
                </li>
              )
            })}
        </ul>
        <div className="pagerContent">
          <Pager pager={pager} />
        </div>
      </main>
    </Layout>
  )
}

export default EventIndexPage
